import atomWithDebounce from "@/utils/atomWithDebounce";
import { formatDecimalPercent, formatDollar } from "@/utils/format";
import {
  isNumber,
  evaluteWithErrors as solve,
  toNumberOrNull,
} from "@/utils/math";
import { Timestamp } from "firebase/firestore";
import { Getter, PrimitiveAtom, atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { focusAtom } from "jotai-optics";
import { DollarAmount, PercentAmount, YearAmount } from "../types";
import { modelAtom } from "./model";
import { PersistableModel } from "./types";

export const createFocusAtom = <T>(
  varName: string,
  intialValue: T,
  group: string = "general",
) =>
  focusAtom(modelAtom, (optic) =>
    optic
      .prop(group as keyof PersistableModel)
      .prop(varName as never)
      .valueOr(intialValue),
  );

export const inputisDebouncingMolecule = atom<PrimitiveAtom<boolean>[]>([]);
export const isInputDebouncingAtom = atom((get) => {
  return get(inputisDebouncingMolecule).some((atom) => get(atom));
});

export const generalInputAtom = <T>(
  varName: string,
  intialValue: T,
  group: string = "general",
) => {
  const debouceStore = atomWithDebounce<T>(undefined as T);
  const focusedAtom = createFocusAtom<T>(varName, intialValue, group);

  const updateEffect = atomEffect((get, set) => {
    const value = get(debouceStore.debouncedValueAtom);
    if (value === undefined) return;
    set(focusedAtom, value);
  });

  return atom<T, [T] | [T, boolean], void>(
    (get) => {
      get(updateEffect);
      return get(focusedAtom);
    },
    (get, set, update, setImmediate = false) => {
      const m = get(inputisDebouncingMolecule);
      if (!m.includes(debouceStore.isDebouncingAtom)) {
        set(inputisDebouncingMolecule, [...m, debouceStore.isDebouncingAtom]);
      }

      if (setImmediate) {
        set(focusedAtom, update);
        return;
      }
      set(debouceStore.debouncedValueAtom, update);
    },
  );
};

export const sidebarAccordionStateAtom = focusAtom(modelAtom, (optic) =>
  optic
    .prop("general")
    .prop("sidebarAccordionState")
    .valueOr<boolean | string[] | null>(true),
);

export const dismissedWelcomeVideoAtom = focusAtom(modelAtom, (optic) =>
  optic.prop("general").prop("dismissedWelcomeVideo").valueOr<boolean>(false),
) as PrimitiveAtom<boolean>;

// purchase information
export const targetPurchasePriceAtom = generalInputAtom<DollarAmount>(
  "targetPurchasePrice",
  null,
);

export const cashFlowAtom = generalInputAtom<DollarAmount>("cashFlow", null);

export const purchaseMultipleAtom = atom((get) =>
  solve<PercentAmount>("targetPurchasePrice / cashFlow", {
    targetPurchasePrice: get(targetPurchasePriceAtom),
    cashFlow: get(cashFlowAtom),
  }),
);

// real estate
export const isRealEstateIncludedAtom = createFocusAtom<boolean>(
  "isRealEstateIncludedAtom",
  false,
);

export const realEstateIsDollarFormatAtom = createFocusAtom<boolean>(
  "realEstateIsDollarFormat",
  true,
);

export const realEstateValueInputDollarAtom = generalInputAtom<DollarAmount>(
  "realEstateDollarValue",
  500000,
);

export const realEstateValuePercentAtom = generalInputAtom<PercentAmount>(
  "realEstatePercentValue",
  0.3,
);

export const realEstateDollarValueAtom = atom((get) => {
  const realEstateIsDollarFormat = get(realEstateIsDollarFormatAtom);
  const realEstateValueInputDollar = get(realEstateValueInputDollarAtom);
  const realEstateValuePercent = get(realEstateValuePercentAtom);
  const targetPurchasePrice = get(targetPurchasePriceAtom);

  return realEstateIsDollarFormat
    ? realEstateValueInputDollar
    : Number(targetPurchasePrice) * Number(realEstateValuePercent);
});

export const realEstateBusinessValueInputDollarAtom =
  generalInputAtom<DollarAmount>("realEstateBusinessValue", 500000);

export const realEstateBusinessValuePercentAtom = atom<
  number,
  [number | null],
  void
>(
  (get) => {
    return Math.max(0, 1 - Number(get(realEstateValuePercentAtom)));
  },
  (_get, set, value) => {
    set(realEstateValuePercentAtom, 1 - Number(value));
  },
);

export const realEstateBusinessValueDollarAtom = atom((get) => {
  const realEstateDollarValue = get(realEstateDollarValueAtom);
  const targetPurchasePrice = get(targetPurchasePriceAtom);

  return Number(targetPurchasePrice) - Number(realEstateDollarValue);
});

export const isRealEstateErrorValueProvidedAtom = atom((get) => {
  return (
    !get(realEstateDollarValueAtom) || !get(realEstateBusinessValueDollarAtom)
  );
});

export const isRealEstateErrorPercentWithinBoundsAtom = atom((get) => {
  const total = Number(
    solve<PercentAmount>("a + b + c", {
      a: get(realEstateBusinessValuePercentAtom),
      b: get(realEstateValuePercentAtom),
      c: get(sellersNotePercentAtom),
    }),
  );

  return (
    get(isRealEstateIncludedAtom) &&
    !get(realEstateIsDollarFormatAtom) &&
    total === 1
  );
});

export const isRealEstateErrorDollarTotalExceedsPurchasePriceAtom = atom(
  (get) => {
    const total = solve<DollarAmount>("a + b", {
      a: get(realEstateDollarValueAtom),
      b: get(realEstateBusinessValueDollarAtom),
    });

    return (
      Number(total) > Number(get(targetPurchasePriceAtom)) &&
      get(isRealEstateIncludedAtom) &&
      get(realEstateIsDollarFormatAtom)
    );
  },
);

export const realEstateErrorMessageAtom = atom((get) => {
  // make dep rerender
  get(isRealEstateErrorDollarTotalExceedsPurchasePriceAtom);
  get(isRealEstateErrorPercentWithinBoundsAtom);

  if (!get(isRealEstateIncludedAtom)) return null;

  if (get(isRealEstateErrorPercentWithinBoundsAtom)) {
    return "Percentages must add up to 100%";
  }

  if (get(isRealEstateErrorDollarTotalExceedsPurchasePriceAtom)) {
    return "Combined value of the Business and Real Estate does not equal Purchase Price.";
  }

  return null;
});

export const isRealEstateInErrorAtom = atom((get) => {
  return !!get(realEstateErrorMessageAtom);
});

// finance structure

export const isEquityInvestorsIncludedAtom = createFocusAtom<boolean>(
  "isEquityInvestorsIncludedAtom",
  false,
);

export const financeStructureIsDollarFormatAtom = createFocusAtom<boolean>(
  "financeStructureIsDollarFormat",
  false,
);

export const sbaLoanPercentAtom = generalInputAtom<PercentAmount>(
  "sbaLoanPercent",
  0.8,
);

export const equityPercentAtom = generalInputAtom<PercentAmount>(
  "equityPercent",
  0.1,
);

export const equityInvestorPercentAtom = generalInputAtom<PercentAmount>(
  "equityInvestorPercent",
  0,
);

export const sellersNotePercentAtom = generalInputAtom<PercentAmount>(
  "sellersNotePercent",
  0.1,
);

export const sbaLoanInputDollarAtom = generalInputAtom<DollarAmount>(
  "sbaLoanDollar",
  0,
);

export const equityInputDollarAtom = generalInputAtom<DollarAmount>(
  "equityDollar",
  0,
);

export const equityInvestorInputDollarAtom = generalInputAtom<DollarAmount>(
  "equityInvestorDollar",
  0,
);

export const sellersNoteInputDollarAtom = generalInputAtom<DollarAmount>(
  "sellersNoteDollar",
  0,
);

export const financeStructureTotalPercentAtom = atom((get) => {
  return solve<PercentAmount>("a + b + c + d", {
    a: get(sbaLoanPercentAtom),
    b: get(sellersNotePercentAtom),
    c: get(equityPercentAtom),
    d: get(isEquityInvestorsIncludedAtom) ? get(equityInvestorPercentAtom) : 0,
  });
});

export const financeStructureErrorPercentAtom = atom((get) => {
  const total = get(financeStructureTotalPercentAtom);

  if (get(financeStructureIsDollarFormatAtom)) return false;

  return isNumber(total) && total !== 1;
});

export const financeStructureTotalDollarAtom = atom((get) => {
  return solve<DollarAmount>("a + b + c + d", {
    a: get(sbaLoanInputDollarAtom),
    b: get(equityInputDollarAtom),
    c: get(sellersNoteInputDollarAtom),
    d: get(isEquityInvestorsIncludedAtom)
      ? get(equityInvestorInputDollarAtom)
      : 0,
  });
});

export const financeStructureErrorDollarAtom = atom((get) => {
  const purchasePrice = get(targetPurchasePriceAtom);
  const total = get(financeStructureTotalDollarAtom);
  if (!get(financeStructureIsDollarFormatAtom)) return false;

  return isNumber(total) && total !== purchasePrice;
});

export const financeStructureErrorSbaNoGreaterThan90PercentAtom = atom(
  (get) => {
    if (get(financeStructureIsDollarFormatAtom)) return false;
    return Number(get(sbaLoanPercentAtom)) > 0.9;
  },
);

export const financeStructureErrorMessageAtom = atom((get) => {
  if (get(financeStructureErrorSbaNoGreaterThan90PercentAtom)) {
    return `SBA 7(a) loan percentage must be less than 90%`;
  }

  if (
    get(financeStructureErrorPercentAtom) &&
    get(financeStructureTotalPercentAtom)
  ) {
    return `Percentages must add up to 100%. Current percent total is ${formatDecimalPercent(
      get(financeStructureTotalPercentAtom),
    )}
      `;
  }

  if (get(financeStructureErrorDollarAtom)) {
    return `Total dollar amount must equal purchase price. Current total is ${formatDollar(
      (Number(get(sbaLoanInputDollarAtom)) || 0) +
        (Number(get(equityInputDollarAtom)) || 0) +
        (Number(get(sellersNoteInputDollarAtom)) || 0),
    )}
      `;
  }

  return null;
});

export const isFinanceStructureInErrorAtom = atom((get) => {
  return !!get(financeStructureErrorMessageAtom);
});

export const sbaLoanDollarAtom = atom((get) =>
  get(financeStructureIsDollarFormatAtom)
    ? get(sbaLoanInputDollarAtom)
    : Number(get(targetPurchasePriceAtom)) * Number(get(sbaLoanPercentAtom)),
);

export const equityDollarAtom = atom((get) =>
  get(financeStructureIsDollarFormatAtom)
    ? get(equityInputDollarAtom)
    : Number(get(targetPurchasePriceAtom)) * Number(get(equityPercentAtom)),
);

export const equityInvestorDollarAtom = atom((get) => {
  const isEquityInvestorsIncluded = get(isEquityInvestorsIncludedAtom);
  const equityInvestorInputDollar = get(equityInvestorInputDollarAtom);
  const financeStructureIsDollarFormat = get(
    financeStructureIsDollarFormatAtom,
  );

  const targetPurchasePrice = get(targetPurchasePriceAtom);
  const equityInvestorPercent = get(equityInvestorPercentAtom);

  if (!isEquityInvestorsIncluded) return 0;
  if (financeStructureIsDollarFormat) return equityInvestorInputDollar;

  return Number(targetPurchasePrice) * Number(equityInvestorPercent);
});

export const sellersNoteDollarAtom = atom((get) =>
  get(financeStructureIsDollarFormatAtom)
    ? get(sellersNoteInputDollarAtom)
    : Number(get(targetPurchasePriceAtom)) *
      Number(get(sellersNotePercentAtom)),
);

// equity investors

export const equityInvestorPreferredEquityRateAtom =
  generalInputAtom<PercentAmount>("equityInvestorPreferredEquityRate", 0.1);

export const equityInvestorEquityStepUpAtom = generalInputAtom<PercentAmount>(
  "equityInvestorEquityStepUp",
  2,
);

export const equityInvestorStartPrincipalRepaymentsAtom =
  createFocusAtom<YearAmount>("equityInvestorStartPrincipalRepayments", 1);

export const equityInvestorStartPreferredDividendsAtom =
  createFocusAtom<YearAmount>("equityInvestorStartPreferredDividends", 1);

// lender line of credit
export const lenderLineOfCreditTotalAmountAtom = generalInputAtom<DollarAmount>(
  "lenderTotalAmount",
  0,
);

export const lenderLineOfCreditDayOneDrawdownAtom =
  generalInputAtom<DollarAmount>("lenderDayOneDrawdown", null);

export const lenderLineOfCreditErrorMaxValue = atom((get) => {
  return (
    Number(get(lenderLineOfCreditDayOneDrawdownAtom)) >
    Number(get(lenderLineOfCreditTotalAmountAtom))
  );
});

export const lenderLineOfCreditErrorMessageAtom = atom((get) => {
  if (get(lenderLineOfCreditErrorMaxValue)) {
    return `'Day One Drawdown' cannot exceed Total Amount`;
  }

  return null;
});

export const isLenderLineOfCreditInErrorAtom = atom((get) => {
  return !!get(lenderLineOfCreditErrorMessageAtom);
});

// deal expenses

export const qualityOfEarningsAtom = generalInputAtom<DollarAmount>(
  "qualityOfEarnings",
  20000,
);

export const legalExpensesAtom = generalInputAtom<DollarAmount>(
  "legalExpenses",
  40000,
);

export const otherDealExpensesAtom = generalInputAtom<DollarAmount>(
  "otherDealExpenses",
  0,
);

export const totalDealExpensesAtom = atom((get) =>
  solve<DollarAmount>("a + b + c", {
    a: Number(get(qualityOfEarningsAtom)) || 0,
    b: Number(get(legalExpensesAtom)) || 0,
    c: Number(get(otherDealExpensesAtom)) || 0,
  }),
);

// sba loan
export const sbaLoanInterestRateAtom = generalInputAtom<PercentAmount>(
  "sbaLoanInterestRate",
  0.115,
);

export const sbaLoanStartDateAtom = createFocusAtom<Timestamp>(
  "sbaLoanStartDateAtom",
  Timestamp.fromDate(new Date()),
);

// sellers note
export const sellersNoteInterestRateAtom = generalInputAtom<PercentAmount>(
  "sellersNoteInterestRate",
  0.07,
);
export const sellersNoteAmortizationScheduleAtom = generalInputAtom<YearAmount>(
  "sellersNoteAmortizationSchedule",
  5,
);

export const sellersNoteStartDateAtom = createFocusAtom<Timestamp>(
  "sellersNoteStartDateAtom",
  Timestamp.fromDate(new Date()),
);

export const sellersNoteHasStandbyProvisionAtom = createFocusAtom<boolean>(
  "hasStandbyProvision",
  false,
);

export const sellersNoteStandbyPeriodAtom = generalInputAtom<YearAmount>(
  "sellersNoteStandbyPeriod",
  2,
);

export const sellersNoteStandbyPeriodRangeErrorAtom = atom((get) => {
  const val = Number(get(sellersNoteStandbyPeriodAtom));

  return (
    !val || val < 1 || val > Number(get(sellersNoteAmortizationScheduleAtom))
  );
});

export const sellersNoteStandbyPeriodErrorMessageAtom = atom((get) => {
  if (get(sellersNoteStandbyPeriodRangeErrorAtom)) {
    return `Standby Period must be between 1 and the Amortization Schedule (${get(
      sellersNoteAmortizationScheduleAtom,
    )} years)`;
  }

  return null;
});

export const sellersNoteStandbyIsFullStandbyAtom = createFocusAtom<boolean>(
  "sellersNoteStandbyIsFullStandby",
  true,
);

export const sellersNoteStandbyAccrueInterestInFullStandbyAtom =
  createFocusAtom<boolean>(
    "sellersNoteStandbyAccrueInterestInFullStandby",
    false,
  );

// company sale
export const companySaleIncludedAtom = createFocusAtom<boolean>(
  "isCompanySaleIncluded",
  false,
);

export const companySaleYearAtom = generalInputAtom<YearAmount>(
  "companySaleYear",
  10,
);

export const companySaleExitMultipleAtom = generalInputAtom<PercentAmount>(
  "companySaleExitMultiple",
  0,
);

// buyer's compensation
export const buyerCompBuyersSalaryAtom = generalInputAtom<DollarAmount>(
  "buyerCompBuyersSalary",
  125000,
);
export const buyerCompPayrollTaxesAtom = generalInputAtom<DollarAmount>(
  "buyerCompPayrollTaxes",
  20000,
);
export const buyerCompHealthBenefitsAtom = generalInputAtom<DollarAmount>(
  "buyerCompHealthBenefits",
  7500,
);

export const buyerCompTotalAtom = atom<DollarAmount>((get) =>
  solve<DollarAmount>("a + b + c", {
    a: get(buyerCompBuyersSalaryAtom) || 0,
    b: get(buyerCompPayrollTaxesAtom) || 0,
    c: get(buyerCompHealthBenefitsAtom) || 0,
  }),
);

// operational assumptions
export const companysTaxRateAtom = generalInputAtom<PercentAmount>(
  "buyerTaxRate",
  0.37,
);
export const buyerDeprAndAmortScheduleAtom = generalInputAtom<YearAmount>(
  "buyerDeprAndAmortSchedule",
  15,
);
export const buyerAnnualCapExAtom = generalInputAtom<DollarAmount>(
  "buyerAnnualCapEx",
  0,
);

// deal fees
export const isRollinDealExpensesAtom = createFocusAtom<boolean>(
  "isRollinDealExpenses",
  true,
);

export const isRollinLoanTransactionFeeAtom = createFocusAtom<boolean>(
  "isRollinLoanTransactionFee",
  true,
);

export const isRollinSBAGuarantyFeeAtom = createFocusAtom<boolean>(
  "isRollinSBAGuarantyFee",
  true,
);

export const loanTransactionFeeAtom = atom((get) => {
  if (get(sbaLoanDollarAtom) === 0) return 0;
  return get(isRealEstateIncludedAtom) ? 30000 : 20000;
});

export const sbaTotalLoanAmountWithoutFeesAtom = atom(
  (get) =>
    Number(get(sbaLoanDollarAtom)) +
    Number(get(lenderLineOfCreditTotalAmountAtom)),
);

export const sbaTotalLoanAmountWithoutGuarantyFeeAtom = atom((get) => {
  const loanTransactionFee = get(isRollinLoanTransactionFeeAtom)
    ? Number(get(loanTransactionFeeAtom))
    : 0;

  const dealExpenses = get(isRollinDealExpensesAtom)
    ? Number(get(totalDealExpensesAtom))
    : 0;

  return (
    Number(get(sbaLoanDollarAtom)) +
    Number(get(lenderLineOfCreditTotalAmountAtom)) +
    loanTransactionFee +
    dealExpenses
  );
});

const calcGuarantyFee = (
  get: Getter,
  underMilPercent: number,
  overMillPercent: number,
) => {
  const sbaTotal = get(sbaTotalLoanAmountWithoutGuarantyFeeAtom);

  const upToOneMiltotalLoanAmount =
    sbaTotal < 1333333.33 ? sbaTotal : 1333333.33;

  const feeUpToOneMiltotalLoanAmount =
    upToOneMiltotalLoanAmount * 0.75 * underMilPercent;

  const overOneMilTotalLoanAmount =
    sbaTotal >= 1333333.33 ? sbaTotal - upToOneMiltotalLoanAmount : 0;

  const feeOverOneMilTotalLoanAmount =
    overOneMilTotalLoanAmount * 0.75 * overMillPercent;

  return feeUpToOneMiltotalLoanAmount + feeOverOneMilTotalLoanAmount;
};

export const sbaLoanSize1To2MilFeeAtom = atom((get) =>
  calcGuarantyFee(get, 0.0145, 0.017),
);
export const sbaLoanSizeOver2MilFeeAtom = atom((get) =>
  calcGuarantyFee(get, 0.035, 0.0375),
);

export const sbaGuarantyFeeAtom = atom((get) => {
  get(sbaLoanSize1To2MilFeeAtom);
  get(sbaLoanSizeOver2MilFeeAtom);

  return get(sbaTotalLoanAmountWithoutGuarantyFeeAtom) < 2000000
    ? get(sbaLoanSize1To2MilFeeAtom)
    : get(sbaLoanSizeOver2MilFeeAtom);
});

export const sbaLoanOptionalFees = atom((get) => {
  const loanTransactionFee = get(isRollinLoanTransactionFeeAtom)
    ? get(loanTransactionFeeAtom)
    : 0;

  const sbaguarantyFee = get(isRollinSBAGuarantyFeeAtom)
    ? get(sbaGuarantyFeeAtom)
    : 0;

  const dealExpenses = get(isRollinDealExpensesAtom)
    ? Number(get(totalDealExpensesAtom)) || 0
    : 0;

  return loanTransactionFee + sbaguarantyFee + dealExpenses;
});

export const sbaLoanTotalWithOptionalFeesAtom = atom((get) => {
  return get(sbaTotalLoanAmountWithoutFeesAtom) + get(sbaLoanOptionalFees);
});

export const sbaLoanTotalWithFeesWithoutLOCAtom = atom((get) => {
  return (
    get(sbaLoanTotalWithOptionalFeesAtom) -
    Number(get(lenderLineOfCreditTotalAmountAtom) || 0)
  );
});

export const businessAcquisitionCostAtom = atom((get) => {
  const isRealEstateIncluded = get(isRealEstateIncludedAtom);
  const targetPurchasePrice = get(targetPurchasePriceAtom);
  const realEstateBusinessValueDollar = get(realEstateBusinessValueDollarAtom);

  return !isRealEstateIncluded
    ? targetPurchasePrice
    : realEstateBusinessValueDollar;
});

export const cashRequiredByBorrowerClosingDayAtom = atom((get) => {
  const equityDollar = Number(get(equityDollarAtom));
  const sbaGuarantyFee = !get(isRollinSBAGuarantyFeeAtom)
    ? Number(get(sbaGuarantyFeeAtom))
    : 0;
  const loanTransactionFee = !get(isRollinLoanTransactionFeeAtom)
    ? Number(get(loanTransactionFeeAtom))
    : 0;

  const equityInvestorDollar =
    toNumberOrNull(get(equityInvestorDollarAtom)) || 0;

  return (
    equityDollar + sbaGuarantyFee + loanTransactionFee + equityInvestorDollar
  );
});

export const cashToSellerOnClosingDayAtom = atom(
  (get) => Number(get(equityDollarAtom)) + Number(get(sbaLoanDollarAtom)),
);

export const individualInvestorReturnStartDollarAtom =
  generalInputAtom<DollarAmount>("individualInvestorReturnStartDollar", 100000);
