//////
// This file was created using the buildDocManifest.js file 
// in the scripts directory. Do not modify this file directly.
//
// npm run build:docManifest
//////

import { lazyReactFallback } from "@/utils/lazy";
export const manifest = {
  "@/docs/pages/about-us.mdx": lazyReactFallback(
    () => import("@/docs/pages/about-us.mdx")
  ),
  "@/docs/pages/home/cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/pages/home/cash-flow.mdx")
  ),
  "@/docs/pages/home/finance-structrue.mdx": lazyReactFallback(
    () => import("@/docs/pages/home/finance-structrue.mdx")
  ),
  "@/docs/pages/home/loan-assumption-sba-7-amortization.mdx": lazyReactFallback(
    () => import("@/docs/pages/home/loan-assumption-sba-7-amortization.mdx")
  ),
  "@/docs/pages/home/loan-assumption-sba-7-interest-rate.mdx": lazyReactFallback(
    () => import("@/docs/pages/home/loan-assumption-sba-7-interest-rate.mdx")
  ),
  "@/docs/pages/home/loan-assumption-sellers-note-amortization.mdx": lazyReactFallback(
    () => import("@/docs/pages/home/loan-assumption-sellers-note-amortization.mdx")
  ),
  "@/docs/pages/home/loan-assumption-sellers-note-interest-rate.mdx": lazyReactFallback(
    () => import("@/docs/pages/home/loan-assumption-sellers-note-interest-rate.mdx")
  ),
  "@/docs/pages/home/target-purchase-multiple.mdx": lazyReactFallback(
    () => import("@/docs/pages/home/target-purchase-multiple.mdx")
  ),
  "@/docs/pages/kitchen-sink.mdx": lazyReactFallback(
    () => import("@/docs/pages/kitchen-sink.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-actual-number-payments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-actual-number-payments.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-interest-rate.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-interest-rate.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-loan-amount.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-loan-amount.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-loan-payoff-date.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-loan-payoff-date.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-loan-period.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-loan-period.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-loan-start-date.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-loan-start-date.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-scheduled-monthly-payment.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-scheduled-monthly-payment.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-scheduled-periods.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-scheduled-periods.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-table-cumlitive-interest.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-table-cumlitive-interest.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-table-date.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-table-date.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-table-ending-balance.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-table-ending-balance.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-table-extra-payment.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-table-extra-payment.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-table-interest.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-table-interest.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-table-principal.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-table-principal.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-table-scheduled-payment.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-table-scheduled-payment.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-table-total-payment.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-table-total-payment.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-table-year.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-table-year.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-total-extra-payments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-total-extra-payments.mdx")
  ),
  "@/docs/pages/model/amortization/sb7a-total-interest.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sb7a-total-interest.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-actual-number-payments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-actual-number-payments.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-interest-rate.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-interest-rate.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-loan-amount.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-loan-amount.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-loan-payoff-date.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-loan-payoff-date.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-loan-period.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-loan-period.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-loan-start-date.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-loan-start-date.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-scheduled-monthly-payment.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-scheduled-monthly-payment.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-scheduled-periods.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-scheduled-periods.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-table-cumlitive-interest.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-table-cumlitive-interest.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-table-date.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-table-date.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-table-ending-balance.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-table-ending-balance.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-table-extra-payment.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-table-extra-payment.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-table-interest.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-table-interest.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-table-principal.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-table-principal.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-table-scheduled-payment.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-table-scheduled-payment.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-table-total-payment.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-table-total-payment.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-table-year.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-table-year.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-total-extra-payments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-total-extra-payments.mdx")
  ),
  "@/docs/pages/model/amortization/sellers-total-interest.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/amortization/sellers-total-interest.mdx")
  ),
  "@/docs/pages/model/formulas/gross-profit.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/gross-profit.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-buyers-adj-ebitda-margins.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-buyers-adj-ebitda-margins.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-buyers-adj-ebitda.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-buyers-adj-ebitda.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-distributable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-distributable-free-cash-flow.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-equity-investor-distributions.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-equity-investor-distributions.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-income-taxes.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-income-taxes.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-line-of-credit-total.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-line-of-credit-total.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-net-income.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-net-income.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-sba7a-loan-total.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-sba7a-loan-total.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-sellers-adj-ebitda-margins.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-sellers-adj-ebitda-margins.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-sellers-adj-ebitda.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-sellers-adj-ebitda.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-sellers-ebitda.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-sellers-ebitda.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-sellers-note-total.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-sellers-note-total.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-taxable-income.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-taxable-income.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-total-debt-service.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-total-debt-service.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-unlevered-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-unlevered-free-cash-flow.mdx")
  ),
  "@/docs/pages/model/formulas/historicals-usable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/historicals-usable-free-cash-flow.mdx")
  ),
  "@/docs/pages/model/formulas/projections-distributable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/projections-distributable-free-cash-flow.mdx")
  ),
  "@/docs/pages/model/formulas/projections-ebitda.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/projections-ebitda.mdx")
  ),
  "@/docs/pages/model/formulas/projections-equity-investor-distributions.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/projections-equity-investor-distributions.mdx")
  ),
  "@/docs/pages/model/formulas/projections-income-taxes.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/projections-income-taxes.mdx")
  ),
  "@/docs/pages/model/formulas/projections-line-of-credit-total-payments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/projections-line-of-credit-total-payments.mdx")
  ),
  "@/docs/pages/model/formulas/projections-net-income.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/projections-net-income.mdx")
  ),
  "@/docs/pages/model/formulas/projections-operating-income.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/projections-operating-income.mdx")
  ),
  "@/docs/pages/model/formulas/projections-sba7a-loan-total-payments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/projections-sba7a-loan-total-payments.mdx")
  ),
  "@/docs/pages/model/formulas/projections-sellers-note-total-payments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/projections-sellers-note-total-payments.mdx")
  ),
  "@/docs/pages/model/formulas/projections-taxable-income.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/projections-taxable-income.mdx")
  ),
  "@/docs/pages/model/formulas/projections-total-debt-service.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/projections-total-debt-service.mdx")
  ),
  "@/docs/pages/model/formulas/projections-unlevered-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/projections-unlevered-free-cash-flow.mdx")
  ),
  "@/docs/pages/model/formulas/projections-usable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/formulas/projections-usable-free-cash-flow.mdx")
  ),
  "@/docs/pages/model/historicals/amortization-of-deal-expenses.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/amortization-of-deal-expenses.mdx")
  ),
  "@/docs/pages/model/historicals/annual-maintenance-capex.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/annual-maintenance-capex.mdx")
  ),
  "@/docs/pages/model/historicals/buyers-adj-ebitda-margins.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/buyers-adj-ebitda-margins.mdx")
  ),
  "@/docs/pages/model/historicals/buyers-adj-ebitda.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/buyers-adj-ebitda.mdx")
  ),
  "@/docs/pages/model/historicals/buyers-compensation.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/buyers-compensation.mdx")
  ),
  "@/docs/pages/model/historicals/buyers-purchase-multiple.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/buyers-purchase-multiple.mdx")
  ),
  "@/docs/pages/model/historicals/companys-tax-rate.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/companys-tax-rate.mdx")
  ),
  "@/docs/pages/model/historicals/cost-of-goods-sold.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/cost-of-goods-sold.mdx")
  ),
  "@/docs/pages/model/historicals/depreciation-and-amortization.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/depreciation-and-amortization.mdx")
  ),
  "@/docs/pages/model/historicals/distributable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/distributable-free-cash-flow.mdx")
  ),
  "@/docs/pages/model/historicals/dscr.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/dscr.mdx")
  ),
  "@/docs/pages/model/historicals/equity-investor-distributions.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/equity-investor-distributions.mdx")
  ),
  "@/docs/pages/model/historicals/equity-investor-prefered-dividends.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/equity-investor-prefered-dividends.mdx")
  ),
  "@/docs/pages/model/historicals/equity-investor-principal-repayments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/equity-investor-principal-repayments.mdx")
  ),
  "@/docs/pages/model/historicals/equity-investor-profit-distributions.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/equity-investor-profit-distributions.mdx")
  ),
  "@/docs/pages/model/historicals/gross-margin.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/gross-margin.mdx")
  ),
  "@/docs/pages/model/historicals/gross-profit.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/gross-profit.mdx")
  ),
  "@/docs/pages/model/historicals/gross-revenue.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/gross-revenue.mdx")
  ),
  "@/docs/pages/model/historicals/income-taxes.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/income-taxes.mdx")
  ),
  "@/docs/pages/model/historicals/line-of-credit-interest.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/line-of-credit-interest.mdx")
  ),
  "@/docs/pages/model/historicals/line-of-credit-principal.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/line-of-credit-principal.mdx")
  ),
  "@/docs/pages/model/historicals/line-of-credit-total.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/line-of-credit-total.mdx")
  ),
  "@/docs/pages/model/historicals/net-income.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/net-income.mdx")
  ),
  "@/docs/pages/model/historicals/new-operating-expenses.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/new-operating-expenses.mdx")
  ),
  "@/docs/pages/model/historicals/operating-expenses.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/operating-expenses.mdx")
  ),
  "@/docs/pages/model/historicals/other-income.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/other-income.mdx")
  ),
  "@/docs/pages/model/historicals/sba-7a-loan-interest.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/sba-7a-loan-interest.mdx")
  ),
  "@/docs/pages/model/historicals/sba-7a-loan-principal.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/sba-7a-loan-principal.mdx")
  ),
  "@/docs/pages/model/historicals/sba7a-loan-total.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/sba7a-loan-total.mdx")
  ),
  "@/docs/pages/model/historicals/sellers-addbacks.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/sellers-addbacks.mdx")
  ),
  "@/docs/pages/model/historicals/sellers-adj-ebitda-margins.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/sellers-adj-ebitda-margins.mdx")
  ),
  "@/docs/pages/model/historicals/sellers-adj-ebitda.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/sellers-adj-ebitda.mdx")
  ),
  "@/docs/pages/model/historicals/sellers-depreciation-amortization.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/sellers-depreciation-amortization.mdx")
  ),
  "@/docs/pages/model/historicals/sellers-ebitda.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/sellers-ebitda.mdx")
  ),
  "@/docs/pages/model/historicals/sellers-income-taxes.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/sellers-income-taxes.mdx")
  ),
  "@/docs/pages/model/historicals/sellers-interest-expenses.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/sellers-interest-expenses.mdx")
  ),
  "@/docs/pages/model/historicals/sellers-note-interest.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/sellers-note-interest.mdx")
  ),
  "@/docs/pages/model/historicals/sellers-note-principal.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/sellers-note-principal.mdx")
  ),
  "@/docs/pages/model/historicals/sellers-note-total.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/sellers-note-total.mdx")
  ),
  "@/docs/pages/model/historicals/taxable-income.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/taxable-income.mdx")
  ),
  "@/docs/pages/model/historicals/total-debt-service.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/total-debt-service.mdx")
  ),
  "@/docs/pages/model/historicals/unlevered-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/unlevered-free-cash-flow.mdx")
  ),
  "@/docs/pages/model/historicals/usable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/historicals/usable-free-cash-flow.mdx")
  ),
  "@/docs/pages/model/overview/amount-paid-seller.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/amount-paid-seller.mdx")
  ),
  "@/docs/pages/model/overview/cash-required-borrower.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/cash-required-borrower.mdx")
  ),
  "@/docs/pages/model/overview/sources-equity-investor.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/sources-equity-investor.mdx")
  ),
  "@/docs/pages/model/overview/sources-equity-searcher.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/sources-equity-searcher.mdx")
  ),
  "@/docs/pages/model/overview/sources-equity.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/sources-equity.mdx")
  ),
  "@/docs/pages/model/overview/sources-line-of-credit.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/sources-line-of-credit.mdx")
  ),
  "@/docs/pages/model/overview/sources-loan-transaction-fee.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/sources-loan-transaction-fee.mdx")
  ),
  "@/docs/pages/model/overview/sources-sb7a-loan.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/sources-sb7a-loan.mdx")
  ),
  "@/docs/pages/model/overview/sources-sba-guaranty-fee.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/sources-sba-guaranty-fee.mdx")
  ),
  "@/docs/pages/model/overview/sources-sellers-note.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/sources-sellers-note.mdx")
  ),
  "@/docs/pages/model/overview/sources-total-cost.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/sources-total-cost.mdx")
  ),
  "@/docs/pages/model/overview/uses-business-acquisition.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/uses-business-acquisition.mdx")
  ),
  "@/docs/pages/model/overview/uses-deal-expenses.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/uses-deal-expenses.mdx")
  ),
  "@/docs/pages/model/overview/uses-line-of-credit.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/uses-line-of-credit.mdx")
  ),
  "@/docs/pages/model/overview/uses-loan-transaction-fee.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/uses-loan-transaction-fee.mdx")
  ),
  "@/docs/pages/model/overview/uses-real-estate-transaction.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/uses-real-estate-transaction.mdx")
  ),
  "@/docs/pages/model/overview/uses-sba-guaranty-fee.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/uses-sba-guaranty-fee.mdx")
  ),
  "@/docs/pages/model/overview/uses-total-cost.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/overview/uses-total-cost.mdx")
  ),
  "@/docs/pages/model/projections/amortization-of-deal-expenses.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/amortization-of-deal-expenses.mdx")
  ),
  "@/docs/pages/model/projections/annual-maintenance-capex.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/annual-maintenance-capex.mdx")
  ),
  "@/docs/pages/model/projections/base-year.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/base-year.mdx")
  ),
  "@/docs/pages/model/projections/buyers-compensation.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/buyers-compensation.mdx")
  ),
  "@/docs/pages/model/projections/companys-tax-rates.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/companys-tax-rates.mdx")
  ),
  "@/docs/pages/model/projections/cost-of-goods-sold.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/cost-of-goods-sold.mdx")
  ),
  "@/docs/pages/model/projections/depreciation-and-amortization.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/depreciation-and-amortization.mdx")
  ),
  "@/docs/pages/model/projections/distributable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/distributable-free-cash-flow.mdx")
  ),
  "@/docs/pages/model/projections/dscr.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/dscr.mdx")
  ),
  "@/docs/pages/model/projections/ebitda-margins.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/ebitda-margins.mdx")
  ),
  "@/docs/pages/model/projections/ebitda.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/ebitda.mdx")
  ),
  "@/docs/pages/model/projections/equity-investor-distributions.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/equity-investor-distributions.mdx")
  ),
  "@/docs/pages/model/projections/equity-investor-prefered-dividends.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/equity-investor-prefered-dividends.mdx")
  ),
  "@/docs/pages/model/projections/equity-investor-principal-repayments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/equity-investor-principal-repayments.mdx")
  ),
  "@/docs/pages/model/projections/equity-investor-profit-distributions.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/equity-investor-profit-distributions.mdx")
  ),
  "@/docs/pages/model/projections/gross-margins.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/gross-margins.mdx")
  ),
  "@/docs/pages/model/projections/gross-profit.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/gross-profit.mdx")
  ),
  "@/docs/pages/model/projections/gross-revenue-yoy-growth.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/gross-revenue-yoy-growth.mdx")
  ),
  "@/docs/pages/model/projections/gross-revenue.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/gross-revenue.mdx")
  ),
  "@/docs/pages/model/projections/income-taxes.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/income-taxes.mdx")
  ),
  "@/docs/pages/model/projections/investor-proceeds-from-company-sale.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/investor-proceeds-from-company-sale.mdx")
  ),
  "@/docs/pages/model/projections/line-of-credit-interest.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/line-of-credit-interest.mdx")
  ),
  "@/docs/pages/model/projections/line-of-credit-principal.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/line-of-credit-principal.mdx")
  ),
  "@/docs/pages/model/projections/line-of-credit-total-payments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/line-of-credit-total-payments.mdx")
  ),
  "@/docs/pages/model/projections/net-income.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/net-income.mdx")
  ),
  "@/docs/pages/model/projections/operating-expenses.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/operating-expenses.mdx")
  ),
  "@/docs/pages/model/projections/operating-income.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/operating-income.mdx")
  ),
  "@/docs/pages/model/projections/proceeds-from-company-sale.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/proceeds-from-company-sale.mdx")
  ),
  "@/docs/pages/model/projections/sba-7a-loan-extra-payments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/sba-7a-loan-extra-payments.mdx")
  ),
  "@/docs/pages/model/projections/sba-7a-loan-interest.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/sba-7a-loan-interest.mdx")
  ),
  "@/docs/pages/model/projections/sba-7a-loan-principal.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/sba-7a-loan-principal.mdx")
  ),
  "@/docs/pages/model/projections/sba7a-loan-total-payments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/sba7a-loan-total-payments.mdx")
  ),
  "@/docs/pages/model/projections/sellers-note-extra-payments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/sellers-note-extra-payments.mdx")
  ),
  "@/docs/pages/model/projections/sellers-note-interest.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/sellers-note-interest.mdx")
  ),
  "@/docs/pages/model/projections/sellers-note-principal.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/sellers-note-principal.mdx")
  ),
  "@/docs/pages/model/projections/sellers-note-total-payments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/sellers-note-total-payments.mdx")
  ),
  "@/docs/pages/model/projections/taxable-income.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/taxable-income.mdx")
  ),
  "@/docs/pages/model/projections/total-debt-service.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/total-debt-service.mdx")
  ),
  "@/docs/pages/model/projections/unlevered-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/unlevered-free-cash-flow.mdx")
  ),
  "@/docs/pages/model/projections/usable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/projections/usable-free-cash-flow.mdx")
  ),
  "@/docs/pages/model/roi/cap-table-company-ownership.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/cap-table-company-ownership.mdx")
  ),
  "@/docs/pages/model/roi/cap-table-sales-proceeds.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/cap-table-sales-proceeds.mdx")
  ),
  "@/docs/pages/model/roi/cap-table-total-distributions.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/cap-table-total-distributions.mdx")
  ),
  "@/docs/pages/model/roi/cap-table-total-return.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/cap-table-total-return.mdx")
  ),
  "@/docs/pages/model/roi/cash-on-cash-return.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/cash-on-cash-return.mdx")
  ),
  "@/docs/pages/model/roi/id-common-equity.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/id-common-equity.mdx")
  ),
  "@/docs/pages/model/roi/id-cumulative-principal-repaid.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/id-cumulative-principal-repaid.mdx")
  ),
  "@/docs/pages/model/roi/id-external-investors.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/id-external-investors.mdx")
  ),
  "@/docs/pages/model/roi/id-individual-investor-returns.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/id-individual-investor-returns.mdx")
  ),
  "@/docs/pages/model/roi/id-period.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/id-period.mdx")
  ),
  "@/docs/pages/model/roi/id-preferred-divdends.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/id-preferred-divdends.mdx")
  ),
  "@/docs/pages/model/roi/id-preferred-equity.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/id-preferred-equity.mdx")
  ),
  "@/docs/pages/model/roi/id-principal-repayments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/id-principal-repayments.mdx")
  ),
  "@/docs/pages/model/roi/id-profit-distributions.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/id-profit-distributions.mdx")
  ),
  "@/docs/pages/model/roi/id-sale-proceeds.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/id-sale-proceeds.mdx")
  ),
  "@/docs/pages/model/roi/id-searcher.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/id-searcher.mdx")
  ),
  "@/docs/pages/model/roi/id-tax-distributions.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/id-tax-distributions.mdx")
  ),
  "@/docs/pages/model/roi/id-total-investor-distrubutions.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/id-total-investor-distrubutions.mdx")
  ),
  "@/docs/pages/model/roi/id-year.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/id-year.mdx")
  ),
  "@/docs/pages/model/roi/individual-investor-projected-return.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/individual-investor-projected-return.mdx")
  ),
  "@/docs/pages/model/roi/irr.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/irr.mdx")
  ),
  "@/docs/pages/model/roi/moic.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/roi/moic.mdx")
  ),
  "@/docs/pages/model/sidebar/buyersCompAnnualBaseSallary.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/buyersCompAnnualBaseSallary.mdx")
  ),
  "@/docs/pages/model/sidebar/buyersCompHealthBenefits.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/buyersCompHealthBenefits.mdx")
  ),
  "@/docs/pages/model/sidebar/buyersCompPayrollTaxes.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/buyersCompPayrollTaxes.mdx")
  ),
  "@/docs/pages/model/sidebar/buyersCompTotalCompensation.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/buyersCompTotalCompensation.mdx")
  ),
  "@/docs/pages/model/sidebar/companySaleCompanySalePrice.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/companySaleCompanySalePrice.mdx")
  ),
  "@/docs/pages/model/sidebar/companySaleExitMultiple.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/companySaleExitMultiple.mdx")
  ),
  "@/docs/pages/model/sidebar/companySaleIncluded.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/companySaleIncluded.mdx")
  ),
  "@/docs/pages/model/sidebar/companySaleYear.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/companySaleYear.mdx")
  ),
  "@/docs/pages/model/sidebar/dealExpensesLegalExpenses.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/dealExpensesLegalExpenses.mdx")
  ),
  "@/docs/pages/model/sidebar/dealExpensesOtherDealExpenses.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/dealExpensesOtherDealExpenses.mdx")
  ),
  "@/docs/pages/model/sidebar/dealExpensesQualityOfEarnings.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/dealExpensesQualityOfEarnings.mdx")
  ),
  "@/docs/pages/model/sidebar/dealExpensesTotalDealExpenses.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/dealExpensesTotalDealExpenses.mdx")
  ),
  "@/docs/pages/model/sidebar/financeStructureCompanyOwnership.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/financeStructureCompanyOwnership.mdx")
  ),
  "@/docs/pages/model/sidebar/financeStructureEquity.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/financeStructureEquity.mdx")
  ),
  "@/docs/pages/model/sidebar/financeStructureEquityInvestor.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/financeStructureEquityInvestor.mdx")
  ),
  "@/docs/pages/model/sidebar/financeStructureEquityInvestorPreferredDividends.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/financeStructureEquityInvestorPreferredDividends.mdx")
  ),
  "@/docs/pages/model/sidebar/financeStructureEquityInvestorPreferredRate.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/financeStructureEquityInvestorPreferredRate.mdx")
  ),
  "@/docs/pages/model/sidebar/financeStructureEquityInvestorRepayments.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/financeStructureEquityInvestorRepayments.mdx")
  ),
  "@/docs/pages/model/sidebar/financeStructureEquityInvestorStepUp.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/financeStructureEquityInvestorStepUp.mdx")
  ),
  "@/docs/pages/model/sidebar/financeStructureEquityInvestorTerms.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/financeStructureEquityInvestorTerms.mdx")
  ),
  "@/docs/pages/model/sidebar/financeStructureHasEquityInvestor.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/financeStructureHasEquityInvestor.mdx")
  ),
  "@/docs/pages/model/sidebar/financeStructureInvestorsPreferredEquity.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/financeStructureInvestorsPreferredEquity.mdx")
  ),
  "@/docs/pages/model/sidebar/financeStructureSB7a.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/financeStructureSB7a.mdx")
  ),
  "@/docs/pages/model/sidebar/financeStructureSearcherCommonEquity.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/financeStructureSearcherCommonEquity.mdx")
  ),
  "@/docs/pages/model/sidebar/financeStructureSearcherPreferredEquity.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/financeStructureSearcherPreferredEquity.mdx")
  ),
  "@/docs/pages/model/sidebar/financeStructureSellersNote.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/financeStructureSellersNote.mdx")
  ),
  "@/docs/pages/model/sidebar/financeStructureTotalEquity.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/financeStructureTotalEquity.mdx")
  ),
  "@/docs/pages/model/sidebar/lineOfCreditDayOneDrawdown.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/lineOfCreditDayOneDrawdown.mdx")
  ),
  "@/docs/pages/model/sidebar/lineOfCreditTotalAmount.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/lineOfCreditTotalAmount.mdx")
  ),
  "@/docs/pages/model/sidebar/opAssAmortOfGoodwill.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/opAssAmortOfGoodwill.mdx")
  ),
  "@/docs/pages/model/sidebar/opAssCompanyTaxRate.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/opAssCompanyTaxRate.mdx")
  ),
  "@/docs/pages/model/sidebar/opAssMaintenanceCapEx.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/opAssMaintenanceCapEx.mdx")
  ),
  "@/docs/pages/model/sidebar/purchaseInfoCashFlow.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/purchaseInfoCashFlow.mdx")
  ),
  "@/docs/pages/model/sidebar/purchaseInfoPurchaseMultiple.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/purchaseInfoPurchaseMultiple.mdx")
  ),
  "@/docs/pages/model/sidebar/purchaseInfoPurchasePrice.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/purchaseInfoPurchasePrice.mdx")
  ),
  "@/docs/pages/model/sidebar/realestateBuisnessValue.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/realestateBuisnessValue.mdx")
  ),
  "@/docs/pages/model/sidebar/realEstateIncluded.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/realEstateIncluded.mdx")
  ),
  "@/docs/pages/model/sidebar/sba7aLoanAmortSchedule.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/sba7aLoanAmortSchedule.mdx")
  ),
  "@/docs/pages/model/sidebar/sba7aLoanInterestRate.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/sba7aLoanInterestRate.mdx")
  ),
  "@/docs/pages/model/sidebar/sba7aLoanLoanStartDate.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/sba7aLoanLoanStartDate.mdx")
  ),
  "@/docs/pages/model/sidebar/sba7aLoanRollinDealExpenses.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/sba7aLoanRollinDealExpenses.mdx")
  ),
  "@/docs/pages/model/sidebar/sba7aLoanRollInLoanTransactionFee.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/sba7aLoanRollInLoanTransactionFee.mdx")
  ),
  "@/docs/pages/model/sidebar/sba7aLoanRollinSBAGuarantyFee.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/sba7aLoanRollinSBAGuarantyFee.mdx")
  ),
  "@/docs/pages/model/sidebar/sba7aLoanTotal.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/sba7aLoanTotal.mdx")
  ),
  "@/docs/pages/model/sidebar/sellersNoteAmortSchedule.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/sellersNoteAmortSchedule.mdx")
  ),
  "@/docs/pages/model/sidebar/sellersNoteHasStandbyProvision.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/sellersNoteHasStandbyProvision.mdx")
  ),
  "@/docs/pages/model/sidebar/sellersNoteInterestRate.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/sellersNoteInterestRate.mdx")
  ),
  "@/docs/pages/model/sidebar/sellersNoteLoanStartDate.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/sellersNoteLoanStartDate.mdx")
  ),
  "@/docs/pages/model/sidebar/sellersNoteStandbyAccrueInterestInFullStandby.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/sellersNoteStandbyAccrueInterestInFullStandby.mdx")
  ),
  "@/docs/pages/model/sidebar/sellersNoteStandbyIsFullStandby.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/sellersNoteStandbyIsFullStandby.mdx")
  ),
  "@/docs/pages/model/sidebar/sellersNoteStandbyPeriod.mdx": lazyReactFallback(
    () => import("@/docs/pages/model/sidebar/sellersNoteStandbyPeriod.mdx")
  ),
  "@/docs/pages/privacy-policy.mdx": lazyReactFallback(
    () => import("@/docs/pages/privacy-policy.mdx")
  ),
  "@/docs/pages/terms-of-service.mdx": lazyReactFallback(
    () => import("@/docs/pages/terms-of-service.mdx")
  )
} as const;