import { firestore } from "@/firebase";
import { Deal } from "@/hooks/useDeals";
import { atomWithQuery } from "@/utils/atomWithFirebase";
import { collection, query, where } from "firebase/firestore";
import { atom } from "jotai";
import { dealTrackerDocumentIdAtom } from "./dealTracker";

export const collectionRef = collection(firestore, "deal");

export const queryAtom = atom((get) => {
  const id = get(dealTrackerDocumentIdAtom);

  return atomWithQuery<Deal>(
    query(
      collectionRef,
      //orderBy("createdAt", "desc"),
      where("creatorId", "==", id),
      where("status", "==", "published"),
    ),
  );
});

export const dealsAtom = atom((get) => {
  return get(get(queryAtom));
});
